<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">

          <a-col
            :md="8"
            :sm="24"
          >
            <a-form-item label="部门名称">
              <a-input
                v-model="queryParam.deptName"
                placeholder="请输入部门名称"
              />
            </a-form-item>
          </a-col>

          <a-col
            :md="!advanced && 8 || 24"
            :sm="24"
          >
            <span
              class="table-page-search-submitButtons"
              :style="advanced && { float: 'right', overflow: 'hidden' } || {} "
            >
              <a-button
                type="primary"
                @click="$refs.table.refresh(true)"
              >查询</a-button>
              <a-button
                style="margin-left: 8px"
                @click="resetForm"
              >重置</a-button>
            </span>
          </a-col>

        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button
        type="primary"
        @click="$refs.actionForm.add(currentId)"
        icon="plus"
        v-permission="['/dept/add']"
      >新增
      </a-button>
      <!--      <a-button type="danger" @click="$refs.delForm.del()" icon="delete">删除顶部节点</a-button>-->
    </div>

    <s-table
      ref="table"
      size="default"
      :defaultExpandAllRows="true"
      :columns="columns"
      :data="loadData"
      :rowKey="(record) => record.key"
    >
      <span
        slot="status"
        slot-scope="status"
      >
        <span v-if="status===1">
          正常
        </span>
        <span v-else>
          停用
        </span>
      </span>
      <span
        slot="action"
        slot-scope="text, record"
      >
        <a @click="$refs.menuAuthorize.edit(record)" v-permission="['/dept/getOne','/dept/edit']">权限</a>
        <a-divider type="vertical" />
        <a @click="$refs.actionForm.edit(record)" v-permission="['/dept/getOne','/dept/edit']">编辑</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="topRight"
          title="确认删除？"
          @confirm="() => deleteRow(record)"
        >
          <a v-permission="['/dept/del']">删除</a>
        </a-popconfirm>
      </span>
    </s-table>
    <action-form
      ref="actionForm"
      @ok="handleOk"
    />
    <del-form
      ref="delForm"
      @ok="handleOk"
    />
    <menuAuthorize
      ref="menuAuthorize"
      @ok="handleOk"
    />
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { del, deptTree } from '@/api/user-rights/organizemanage'
import actionForm from './acDeptActionForm'
import delForm from './acDeptDelForm'
import menuAuthorize from './acDeptMenuAuthorize'

export default {
  components: {
    STable,
    actionForm,
    menuAuthorize,
    delForm
  },
  props: {
    currentId: {
      type: String,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      advanced: false, // 高级搜索 展开/关闭
      queryParam: {}, // 查询参数
      columns: [ // 表头
        {
          title: '部门名称',
          dataIndex: 'deptNameView'
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '排序',
          dataIndex: 'sort'
        },
        {
          title: '备注',
          dataIndex: 'description',
          scopedSlots: { customRender: 'description' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        this.$set(this.queryParam, 'parentId', this.currentId)
        return deptTree(Object.assign(parameter, this.queryParam)).then((res) => {
          // res.data = res['rows']
          res.totalCount = 0
          res.pageNo = 0
          return res
        })
      }
    }
  },

  methods: {
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    handleOk () {
      this.$refs.table.refresh()
      this.$emit('ok', true)
    },
    /**
     * 部门发生变化时刷新
     * @param currentId 选中的部门id
     */
    deptRefresh () {
      this.$refs.table.refresh(true)
    },
    /**
     * 重置表单
     */
    resetForm () {
      this.queryParam = {
        realName: null,
        status: null,
        phone: null,
        defaultDeptId: this.queryParam.defaultDeptId
      }
    },
    /**
     * 删除部门
     * @param record
     */
    deleteRow (record) {
      try {
        del({ id: record.id }).then((res) => {
          if (res.code === 200) {
            this.$message.success('删除成功')
            this.$refs.table.refresh()
            this.$emit('ok', true)
          }
        })
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>

<style lang="less">
.table-operator {
  margin-bottom: 2px;
}

button {
  margin-right: 8px;
}
</style>
