<template>
  <a-modal
    title="删除部门"
    :width="900"
    :visible="visible"
    :confirmLoading="confirmLoading"
    okText="确认"
    cancelText="取消"
    @ok="handleSubmit"
    @cancel="handleCancel">

    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item
          label="部门"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          has-feedback=""
        >
          <a-tree-select
            v-decorator="['id', {rules: [{required: true, message: '请选择上级部门'}]}]"
            style="width: 100%"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="treeData"
            :replaceFields="{children:'children', title:'name', key:'id', value: 'id' }"
            placeholder="请选择删除部门部门"
            tree-default-expand-all
          >
          </a-tree-select>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { deptTree, del } from '@/api/user-rights/organizemanage'

export default {
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      treeData: []
    }
  },
  methods: {
    del (record) {
      this.handleDeptTree()
      this.visible = true
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      this.confirmLoading = true

      validateFields((errors, values) => {
        if (!errors) {
          del(values).then((res) => {
            this.confirmLoading = false
            if (res.code === 200) {
              this.$message.success('删除成功')
              this.handleCancel()
              this.$emit('ok', values)
            }
          }).finally((res) => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel () {
      this.form.resetFields()
      this.visible = false
    },
    /**
     * taidi
     * 获取组织树信息
     */
    handleDeptTree () {
      this.loading = true
      deptTree({ parentId: 0 }).then((res) => {
        if (res['code'] === 200) {
          this.treeData = res.data
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
